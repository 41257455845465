<template>
    <div>
        <ts-page-title
            :title="$t('bankProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('bankProfile.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper>
                <a-button type="primary" @click="addNew" v-if="$whois.admin() || $whois.agency()">
                    {{ $t("addNew") }}
                </a-button>
            </ts-panel-wrapper>
            <ts-table
                :columns="columns"
                :records="resources"
                :loading="loading"
            >
                <template v-slot="{ record }">
                    <td>{{ record.bank_name }}</td>
                    <td>{{ record.account_number }}</td>
                    <td
											v-if="$whois.admin()"
										>{{ record.agency_name }}</td>
                    <td>{{ record.remarks }}</td>
                    <td class="tw-text-center">
                        <span class="badge bg-primary" v-if="record.is_bank"
                            >Yes</span
                        >
                        <span class="badge bg-danger" v-else>No</span>
                    </td>

                    <td class="text-center tw-space-x-3" v-if="$whois.admin() || $whois.agency()">
                        <a
                            href="#"
                            v-tooltip="$t('edit')"
                            class="text-primary mr-2"
                            @click.prevent="edit(record)"
                        >
                            <i class="fas fa-edit"></i>
                        </a>
                    </td>
                </template>
            </ts-table>
            <div class="d-flex justify-content-end tw-p-4">
                <ts-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-pagination>
            </div>
        </ts-panel>

        <!-- form-action -->
        <a-modal
            v-if="showForm"
            v-model="showForm"
            :title="$t('bankProfile.pageTitle')"
            centered
            :maskClosable="false"
            @cancel="clearEdit"
            :zIndex="1020"
            :footer="null"
        >
            <from-action @cancel="clearEdit" />
        </a-modal>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";

export default {
    name: "bankProfileIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false
        };
    },
    computed: {
        ...mapState("finance/bankProfile", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.finance.bankProfile.search;
            },
            set(newValue) {
                this.$store.commit("finance/bankProfile/SET_SEARCH", newValue);
                this.$store.commit("finance/bankProfile/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                { name: this.$t("bankProfile.name"), sortKey: 'bank_name' },
                { name: this.$t("bankProfile.accountNumber"), sortKey: 'account_number' },
                { name: this.$t("bankProfile.agency"), hidden: this.$whois.admin() },
                { name: this.$t("bankProfile.remark") },
                {
                    name: this.$t("bankProfile.isBank"),
                    style: "width:100px; text-align:center"
                },
                {
                    name: this.$t("actions"),
                    style: "text-align:center; width: 1px;",
                    hidden: this.$whois.admin() | this.$whois.agency()
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("finance/bankProfile/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$notify({ type: "error", text: error.message });
                });
        },
        addNew() {
            this.showForm = true;
        },
        edit(record) {
            this.$store.commit("finance/bankProfile/SET_EDIT_DATA", record);
            this.showForm = true;
        },
        clearEdit() {
            this.$store.commit("finance/bankProfile/SET_EDIT_DATA", {});
            this.showForm = false;
            this.fetchData();
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500)
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("finance/bankProfile/RESET_STATE");
        next();
    }
};
</script>
