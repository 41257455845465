var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ts-page-title',{attrs:{"title":_vm.$t('bankProfile.pageTitle'),"breadcrumb":[
                { text: _vm.$t('home'), href: '/' },
                {
                    text: _vm.$t('bankProfile.pageTitle'),
                    active: true
                }
            ]}}),_c('ts-panel',[_c('ts-panel-wrapper',[(_vm.$whois.admin() || _vm.$whois.agency())?_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.addNew}},[_vm._v(" "+_vm._s(_vm.$t("addNew"))+" ")]):_vm._e()],1),_c('ts-table',{attrs:{"columns":_vm.columns,"records":_vm.resources,"loading":_vm.loading},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var record = ref.record;
return [_c('td',[_vm._v(_vm._s(record.bank_name))]),_c('td',[_vm._v(_vm._s(record.account_number))]),(_vm.$whois.admin())?_c('td',[_vm._v(_vm._s(record.agency_name))]):_vm._e(),_c('td',[_vm._v(_vm._s(record.remarks))]),_c('td',{staticClass:"tw-text-center"},[(record.is_bank)?_c('span',{staticClass:"badge bg-primary"},[_vm._v("Yes")]):_c('span',{staticClass:"badge bg-danger"},[_vm._v("No")])]),(_vm.$whois.admin() || _vm.$whois.agency())?_c('td',{staticClass:"text-center tw-space-x-3"},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('edit')),expression:"$t('edit')"}],staticClass:"text-primary mr-2",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.edit(record)}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-end tw-p-4"},[_c('ts-pagination',{on:{"navigate":_vm.fetchData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1),(_vm.showForm)?_c('a-modal',{attrs:{"title":_vm.$t('bankProfile.pageTitle'),"centered":"","maskClosable":false,"zIndex":1020,"footer":null},on:{"cancel":_vm.clearEdit},model:{value:(_vm.showForm),callback:function ($$v) {_vm.showForm=$$v},expression:"showForm"}},[_c('from-action',{on:{"cancel":_vm.clearEdit}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }