<template>
    <ts-loading-banner :loading="loading">
        <div class="tw-space-y-2">
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{ $t("bankProfile.name") }}</label>
                    <input
                        v-model="model.bank_name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('bank_name')
                        }"
                        :placeholder="$t('bankProfile.name')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('bank_name')"
                    >
                        {{ errors.first("bank_name") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-space-y-1">
                    <label class="required">{{ $t("bankProfile.accountNumber") }}</label>
                    <input
                        v-model="model.account_number"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('account_number')
                        }"
                        :placeholder="$t('bankProfile.accountNumber')"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('account_number')"
                    >
                        {{ errors.first("account_number") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 tw-space-y-1">
                    <label>{{ $t("bankProfile.remark") }}</label>
                    <textarea
                        v-model="model.remarks"
                        rows="3"
                        class="form-control"
                        :placeholder="$t('bankProfile.remark')"
                        :class="{
                            'is-invalid': errors.has('remarks')
                        }"
                    ></textarea>
                    <div class="invalid-feedback" v-if="errors.has('remarks')">
                        {{ errors.first("remarks") }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 tw-space-y-1">
                    <ts-checkbox v-model="model.is_bank">{{
                        $t("bankProfile.isBank")
                    }}</ts-checkbox>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 tw-flex tw-justify-end tw-space-x-3">
                    <ts-button
                        class="mr-2"
                        @click.prevent="() => $emit('cancel')"
                    >
                        {{ $t("cancel") }}
                    </ts-button>
                    <ts-button
                        v-if="!isUpdate"
                        color="primary"
                        @click.prevent="onSave"
                        >{{ $t("save") }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        >{{ $t("update") }}</ts-button
                    >
                </div>
            </div>
        </div>
    </ts-loading-banner>
</template>

<script>
import { isEmpty } from "lodash";
import { mapState } from "vuex";
import { Errors } from "form-backend-validation";

export default {
    name: "branchForm",
    data() {
        return {
            loading: false,
            errors: new Errors(),
            model: {
                bank_id: null,
                bank_name: "",
                remarks: null,
                is_bank: false
            }
        };
    },
    computed: {
        ...mapState("finance/bankProfile", ["edit_data"]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        }
    },
    created() {
        this.setEditData();
    },
    methods: {
        async onSave() {
            this.loading = true;
            this.$store
                .dispatch("finance/bankProfile/store", this.model)
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("fetchData");
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async onUpdate() {
            this.loading = true;
            this.$store
                .dispatch("finance/bankProfile/update", {
                    id: this.model.bank_id,
                    data: this.model
                })
                .then(async response => {
                    this.$notify({ type: "success", text: response.message });
                    this.$emit("cancel");
                })
                .catch(error => {
                    this.$notify({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        setEditData() {
            if (!isEmpty(this.edit_data)) {
                this.model.bank_id = this.edit_data.bank_id;
                this.model.bank_name = this.edit_data.bank_name;
                this.model.account_number = this.edit_data.account_number;
                this.model.remarks = this.edit_data.remarks;
                this.model.is_bank = this.edit_data.is_bank;
            }
        }
    }
};
</script>
